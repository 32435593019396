import React from "react";
import NavigationHelper from "../helpers/navigation";

export default function BlogItemTag(props) {
  const tagLink = NavigationHelper.useGeneratePageURL(
    props.language,
    "blogtag",
    props.tag.name.toLowerCase()
  );
  const tag = props.isLink
    ? `<a href="${tagLink}">${props.tag.name}</a>`
    : props.tag.name;
  const tagHtml = `${tag}${props.tag.isLast ? "" : "<b>,</b> "}`;
  return (
    <>
      <span dangerouslySetInnerHTML={{ __html: tagHtml }}></span>
    </>
  );
}
