import React from "react";
import NavigationHelper from "../helpers/navigation";

export default function BlogCategoryLink(props) {
  return (
    <>
      <a
        href={NavigationHelper.useGeneratePageURL(
          props.language,
          "blogcategory",
          props.categoryTitle.toLowerCase()
        )}
      >
        {props.categoryTitle.toUpperCase()}
      </a>
    </>
  );
}
